import React from "react"
import { useStaticQuery } from "gatsby"
import tw from "twin.macro"
import Layout from "../layout"
import TextPanel from "../components/textPanel"
import SignupForm from "../components/signupForm"
import SEO from "../layout/seo"

const MainContainer = tw.div`
py-24 lg:py-48 mx-auto w-3/4 max-w-screen-md
`
const FormContainer = tw.div`
pb-12 md:pb-24 mx-auto
`

const ErrorPage = () => {
  const data = useStaticQuery(graphql`
    query ErrorHeading {
      webinyHeadlessCms {
        getPageHeadings(where: { page: "404" }) {
          data {
            id
            heading
            subHeading
          }
        }
      }
    }
  `)

  const header = data.webinyHeadlessCms.getPageHeadings.data

  return (
    <>
      <SEO title="404" description="Page not found" />
      <Layout>
        <MainContainer>
          <TextPanel heading={header.heading} subHeading={header.subHeading} />
          <FormContainer>
            <SignupForm
              placeholder="Enter your email and..."
              buttonText="Join the Crowd!"
            ></SignupForm>
          </FormContainer>
        </MainContainer>
      </Layout>
    </>
  )
}

export default ErrorPage
